<template>

  <div class="row" ref="modalForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Name"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <fg-text type="text"
                     label="Description"
                     name="description"
                     fou
                     :rows="5"
                     v-model="formData.description">
            </fg-text>

            <ValidationProvider
              vid="ident"
              rules="required"
              name="The ident"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :disabled="editMode === true"
                        :error="failed ? errors[0]: null"
                        label="Ident"
                        name="ident"
                        fou
                        v-model="formData.ident">
              </fg-input>
            </ValidationProvider>

            <fg-text  type="text"
                     label="Value"
                     name="value"
                     fou
                     :rows="5"
                     v-model="formData.value">
            </fg-text>
            <div class="form-group">
              <label>Image</label>
              <el-tooltip placement="right" v-if="true">
                <div slot="content"></div>
                <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
              </el-tooltip>
              <prime-uploader
                :preview-width="'200px'"
                :preview-height="'200px'"
                :elementNum="1"
                v-model="formData.image"
              >
              </prime-uploader>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="submit"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.go(-1)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, Tooltip} from 'element-ui';
import PrimeUploader from "../../../components/PrimeUploader";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    PrimeUploader,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      modalName: 'Setting',
      routeName: 'settings',
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        description: "",
        ident: "",
        value: "",
      },
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.modalForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit " + this.modalName;
      this.getItem();
    } else {
      this.editMode = false;
      this.formTitle = "Add " + this.modalName;
      this.loader.hide();
    }
  },


  methods: {

    getItem() {
      let data = {
          'id' : this.id,
      };
      this.axios.post(this.routeName + "/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.modalName + " Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    async submit() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid){
        this.handleSubmittingErrors('The given data was invalid.');
        return;
      }
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put(this.routeName + "/update/" + this.id, this.formData);
        successMessage = this.modalName + " Item Updated Successfully";
      } else {
        request = this.axios.post(this.routeName + "/create", this.formData);
        successMessage = this.modalName + " Item Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.go(-1);
      }).catch(async (error) => {
        if (error.response.status === 422) {
          await this.$refs.formValidator.setErrors(error.response.data.errors);
          this.handleSubmittingErrors(error.response.data.message);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    },

  }
}
</script>

<style>
</style>
